/** @jsx jsx */
import { jsx } from "theme-ui"

export default () => (
  <svg
    className="icon"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#888"
  >
    <path d="M10.0051573,20 C15.4718927,20 20,15.4718927 20,9.9948427 C20,4.52810727 15.4615781,0 9.9948427,0 C4.52810727,0 0,4.52810727 0,9.9948427 C0,15.4718927 4.53842187,20 10.0051573,20 Z M10.0051573,18.215575 C5.44610624,18.215575 1.79473956,14.5642084 1.79473956,9.9948427 C1.79473956,5.43579165 5.43579165,1.77411037 9.9948427,1.77411037 C14.5538938,1.77411037 18.215575,5.43579165 18.2259113,9.9948427 C18.2362042,14.5642084 14.5642084,18.215575 10.0051573,18.215575 Z M8.2929345,13.5946364 L13.5327488,10.5002579 C13.9143889,10.2733368 13.9040743,9.72666323 13.5327488,9.51005673 L8.2929345,6.40536359 C7.89066529,6.1784425 7.37493553,6.36410521 7.37493553,6.8076328 L7.37493553,13.2026818 C7.37493553,13.6462094 7.85972151,13.8421867 8.2929345,13.5946364 Z" />
  </svg>
)
